* {
  box-sizing: border-box;
}

.wrapper {
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
  padding-top: 20px;
}

.wrapper__content {
  padding: 20px;
  border-radius: 16px;
  background-color: white;
  margin: 0 20px 20px 20px;
}

.pageTitle {
  font-size: 30px;
  font-weight: 700;
}

.page__header {
  display: flex;
  align-items: end;
  gap: 20px;
  margin-bottom: 20px;
}

header{
  gap: 20px;
}

header a {
  color: #969ea7;
  padding: 0 10px;
}

header a:hover{
  background-color: #1677ff;
  color: white;
}

.titleAPP {
  font-weight: 300;
  color: white;
}

input + .input_name {
  margin-top: 15px;
}

.input_name {
  margin-bottom: 5px;
}

.login_wrapper {
  width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.login_wrapper form {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .table_place {
    overflow: auto;
  }

  header {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto !important;
    gap: 0;
  }

  :where(.css-dev-only-do-not-override-1gwfwyx).ant-layout-header {
    line-height: 40px;
  }
}